import React from 'react'

import {useTheme, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({

  root: {
    marginTop: theme.spacing(1),
  },

  logo: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('desktop')]: {
      marginBottom: theme.spacing(2),
    },
  },

  image: {
    width: '100px',
  },

  title: {
    fontSize: '2rem',
    marginBottom: '0',
    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '2.5rem',
      textAlign: 'center',
      marginBottom: theme.spacing(1),
    },
  },

  subTitle: {
    textAlign: 'center',
    fontWeight: 'normal',
    color: '#555',
    fontSize: '1.0rem',
  },

  description: {
    textAlign: 'center',
    fontWeight: 'normal',
    color: '#555',
    fontSize: '0.8rem',
    paddingTop: theme.spacing(2),
  },

  heart: {
    color: 'red',
  },

  link: {
    textDecoration: 'underline',
  },
}))

export default function Header(){
  const classes = useStyles(useTheme())

  return (
    <Box>
      <Box className={classes.root}>
          <Typography className={classes.logo}>
            <Link href="/">
              <img src="/Webhook-Creator-Logo.png" className={classes.image} alt="Webflow Webhook Creator Tool"/>
            </Link>
          </Typography>

          <Typography variant="h1" className={classes.title}>
              Webflow Webhook Creator
          </Typography>

          <Typography variant="h2" className={classes.subTitle}>
              Finally... using Webhooks with Webflow is easy.
          </Typography>

          <Typography className={classes.description}>
              <span className={classes.heart}>♥️</span> created by <Link href="https://nocodequest.com" target="_blank" className={classes.link}>NoCodeQuest.com</Link>
          </Typography>

      </Box>
    </Box>
  )

}
