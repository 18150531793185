import React, { useState, useEffect } from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import CloseIcon from '@material-ui/icons/Close'
import MenuItem from '@material-ui/core/MenuItem'
import Backdrop from '@material-ui/core/Backdrop'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { makeStyles } from '@material-ui/core/styles'

// CSS Styles
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000000',
  },

  textField: {
    width: '100%',
  },

  selectField: {
    width: '100%',
  },

  paper: {
    width: '100%',
    padding: theme.spacing(4),

    [theme.breakpoints.up('desktop')]: {
      width: '70%',
    },
  },

  formControl: {
    width: '100%',
  },

  close: {
    padding: theme.spacing(0.5),
  },

  alert: {
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('desktop')]: {
      width: '95%',
      marginBottom: theme.spacing(4),
    },
  },
}))

export default function Tool() {

  // Setup
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [successOpen, setSuccessOpen] = useState(false)

  // Errors
  const defaultErrorMessage = "Oops! That didn't work 😱 Make sure all fields are correct and please try again."
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage)

  // Success
  const defaultSuccessMessage = "New Webflow Webhook Added!"
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage)

  // Toggle Notifications
  useEffect(() => {
    if(successOpen){ setAlertOpen(false) }
  }, [successOpen])

  useEffect(() => {
    if(alertOpen){ setSuccessOpen(false) }
  }, [alertOpen])

  // Handlers
  const handleCloseAllNotices = () => {
    handleAlertClose()
    handleSuccessClose()
  }
  const handleAlertClose = () => { setAlertOpen(false) }
  const handleSuccessClose = () => { setSuccessOpen(false) }
  const handleLoadingClose = () => { setLoading(false) }
  const handleLoadingOpen = () => { setLoading(true) }

  // Errors: API Request
  //
  // Display any errors from the remote request to
  // the Webflow API.
  //
  const ApiRequestErrors = () => (
      <GridWrapper>
        <Alert
          severity="error"
          variant="filled"
          className={classes.alert}
          onClose={handleAlertClose}
          action={
            <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={handleAlertClose}
              >
                <CloseIcon />
              </IconButton>
          }
        >
          {errorMessage}
        </Alert>
      </GridWrapper>
  )

  // Successful: API Request
  //
  // Display a success message once the Webhook
  // has been created.
  //
  const ApiRequestSuccess = () => (
      <GridWrapper>
        <Alert
          severity="success"
          variant="filled"
          className={classes.alert}
          onClose={handleSuccessClose}
          action={
            <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={handleSuccessClose}
              >
                <CloseIcon />
              </IconButton>
          }
        >
          {successMessage}
        </Alert>
      </GridWrapper>
  )

  // Grid Wrapper for Form Elements
  const GridWrapper = ({children}) => (
    <Grid container spacing={8}>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  )

  // Create Webhook Form
  //
  // Creating the form that accepts the Webflow API Token and
  // the Webhook Trigger type supported by Webflow.
  //
  const CreateWebhookForm = () => {

    // Form Field & API Response Saved States
    //
    // We save the form field entries into the web browsers localStorage.
    // If that value exists, pre-populate the forms field with it.
    //
    const [apiTokenField, setApiTokenField] = useState(localStorage.getItem('apiTokenField') || '')
    const [webhookTriggerField, setWebhookTriggerField] = useState(localStorage.getItem('webhookTriggerField') || '')
    const [webhookUrlField, setWebhookUrlField] = useState(localStorage.getItem('webhookUrlField') || '')

    // Form Submit Handler
    const handleSubmit = (event) => {
      event.preventDefault()

      handleLoadingOpen()
      handleCloseAllNotices()
      SaveAllFieldsToLocalStorage()
      if(clientSideValidation()){ WebflowGetSite() }
    }

    // Client Side Validation
    //
    // We already use HTML 5 form validation, but Webflow
    // misses the required 'https://' prepended to their
    // webhook url.
    //
    // HTML 5 form field types of 'url' with a regex pattern
    // are spotty (at best).
    //
    // Handle this one-off manually, here.
    //
    const clientSideValidation = () => {
      if(webhookUrlField){
        if(!webhookUrlField.match(/^https:\/\/.*/i)){
          setErrorMessage('The Desintation Endpoint URL must always begin with "https://"')
          setAlertOpen(true)
          handleLoadingClose()
          return false
        }
      }

      return true
    }

    // Add Form Field Entries to Local localStorage
    const SaveAllFieldsToLocalStorage = () => {
      // localStorage.setItem('apiTokenField', apiTokenField)
      // localStorage.setItem('webhookTriggerField', webhookTriggerField)
      // localStorage.setItem('webhookUrlField', webhookUrlField)
    }

    // Webflow: Get Site
    //
    // Getting the Webflow Site ID from the Webflow API token provided
    // using a Netlify Function (e.g. Lambda Function).
    //
    // Returns an Array with the expected data:
    //
    // [{
  	// 		id: '64ee74b9aee91ac17ae3a605',
  	// 	    workspaceId: '636dc3dd2367aa7475824a6b',
  	// 	    displayName: 'Testing Installed Apps',
  	// 	    shortName: 'testing-installed-apps',
  	// 	    previewUrl: '',
  	// 	    timeZone: 'Asia/Tokyo',
  	// 	    createdOn: '2023-08-29T22:44:09.454Z',
  	// 	    lastUpdated: '2023-08-30T00:07:01.694Z',
  	// 	    lastPublished: '2023-08-29T23:49:53.599Z',
  	// 	    customDomains: [],
  	// 	    locales: null
    // }]
    //
    // * id is the Webflow Site ID
    //
    const WebflowGetSite = async () => {
      const response = await fetch('/.netlify/functions/webflow-get-site', {
        method: 'POST',
        body: JSON.stringify({apiTokenField})
      })
      const data = await response.json()
	  
      if(response.status === 200){
        WebflowCreateWebhook(data[0].id)
      }else{
        setErrorMessage(data.message)
        setAlertOpen(true)
        handleLoadingClose()
      }
    }

    // Webflow: Create Webhook
    //
    // Create the Webflow Webhook from the Site ID and Webhook URL passed.
    //
    //
	// id: '64eea2a2b9309fe91c2d540f',
	// triggerType: 'collection_item_created',
	// siteId: '64ee74b9aee91ac17ae3a605',
	// workspaceId: '636dc3dd2367aa7475824a6b',
	// filter: null,
	// lastTriggered: null,
	// createdOn: '2023-08-30T02:00:02.676Z',
	// url: 'https://webhook.site/1748c831-9db4-4f23-9e9d-edf5679638bf'
    //
    // * id: is the Webflow Webhook ID
    // * siteId: is the Webflow Site ID
    //
    const WebflowCreateWebhook = async (webflowSiteId) => {
      const response = await fetch('/.netlify/functions/webflow-create-webhook', {
        method: 'POST',
        body: JSON.stringify({apiTokenField, webhookTriggerField, webhookUrlField, webflowSiteId})
      })
      const data = await response.json()

      if(response.status === 201){
        setSuccessMessage('Your new Webhook has been successfully created.')
        setSuccessOpen(true)
      }else{
        setErrorMessage(data.message)
        setAlertOpen(true)
      }

      handleLoadingClose()
    }

    // Render form
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
          <Paper className={classes.paper}>

            { alertOpen ? <ApiRequestErrors/> : '' }
            { successOpen ? <ApiRequestSuccess/> : '' }

            <Backdrop className={classes.backdrop} open={loading} onClick={handleLoadingClose}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <form onSubmit={handleSubmit}>
              <GridWrapper>
                <TextField
                  required
                  type="text"
                  id="apiTokenField"
                  name="apiTokenField"
                  variant="outlined"
                  label="Webflow API Token"
                  className={classes.textField}
                  onChange={e => setApiTokenField(e.target.value)}
                  value={apiTokenField}
                />
              </GridWrapper>

              <GridWrapper>
                <TextField
                  required
                  id="webhookUrlField"
                  name="webhookUrlField"
                  variant="outlined"
                  label="Destination URL"
                  className={classes.textField}
                  onChange={e => setWebhookUrlField(e.target.value)}
                  value={webhookUrlField}
                />
              </GridWrapper>

              <GridWrapper>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="webhook-tigger-label">Webflow Webhook Trigger</InputLabel>
                  <Select
                    required
                    value={webhookTriggerField}
                    id="webhook-tigger"
                    onChange={e => setWebhookTriggerField(e.target.value)}
                    label="Webflow Webhook Trigger Type"
                    labelId="webhook-tigger-label"
                    className={classes.selectField}
                  >
					<MenuItem value={'page_created'}>Page Created</MenuItem>		
					<MenuItem value={'page_metadata_updated'}>Page Metadata Updated</MenuItem>		
					<MenuItem value={'page_deleted'}>Page Deleted</MenuItem>		
                    <MenuItem value={'collection_item_created'}>Collection Item Created</MenuItem>
                    <MenuItem value={'collection_item_changed'}>Collection Item Updated</MenuItem>
                    <MenuItem value={'collection_item_deleted'}>Collection Item Deleted</MenuItem>
                    <MenuItem value={'collection_item_unpublished'}>Collection Item Unpublished</MenuItem>
                    <MenuItem value={'user_account_added'}>User Account Added</MenuItem>
                    <MenuItem value={'user_account_updated'}>User Account Updated</MenuItem>
                    <MenuItem value={'user_account_deleted'}>User Account Deleted</MenuItem>
                    <MenuItem value={'ecomm_new_order'}>Ecommerce New Order</MenuItem>
                    <MenuItem value={'ecomm_order_changed'}>Ecommerce Order Updated</MenuItem>
                    <MenuItem value={'ecomm_inventory_changed'}>Ecommerce Inventory Updated</MenuItem>
                    <MenuItem value={'site_publish'}>Webflow Site Published</MenuItem>
                    <MenuItem value={'form_submission'}>Webflow Form Submission</MenuItem>
                  </Select>
                </FormControl>
              </GridWrapper>

              <GridWrapper>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  startIcon={<AddCircleOutlineIcon/>}
                  type="submit"
                >
                  Create Webflow Webhook
                </Button>
              </GridWrapper>
            </form>

        </Paper>
      </Box>
    )
  }

  // Main Render
  return <CreateWebhookForm/>

}
