import React from 'react'
import EmailCourse from './globals/EmailCourse'

import {useTheme, makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(4),
    backgroundColor: '#f4f4f0',

    [theme.breakpoints.up('desktop')]: {
      width: '70%',
    },
  },

  contentWrapper: {
    paddingBottom: theme.spacing(5),
  },

  subheadingGrid: {
    paddingTop: theme.spacing(15),
    width: '100%',

    [theme.breakpoints.up('desktop')]: {
      width: '70%',
    },
  },

  subheadingTitle: {
    fontSize: '1.3rem',
    fontWeight: 'normal',
    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '1.8rem',
    },
  },

  subheadingParagraph: {
    fontSize: '1rem',
    fontWeight: 'normal',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '1.1rem',
    },
  },

  instructionsHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0),

    [theme.breakpoints.up('desktop')]: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
    },
  },

  section: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('desktop')]: {
      marginTop: theme.spacing(5),
    },
  },

  link: {
    textDecoration: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(4),
      fontSize: '1.3rem',
    },
  },

  image: {
    width: '100%',

    [theme.breakpoints.up('desktop')]: {
      width: '50%',
    },
  },
}))

const ContentWrapper = ({children}) => {
  const classes = useStyles(useTheme())
  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={classes.contentWrapper}>
      {children}
    </Box>
  )
}

const LinkImage = ({children}) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {children}
    </Box>
  )
}

const LinkTitle = ({children}) => {
  const classes = useStyles(useTheme())
  return (
    <div className={classes.link}>
      {children}
    </div>
  )
}

const SubHeaderGrid = ({children}) => {
  const classes = useStyles(useTheme())
  return (
    <Grid container spacing={0} className={classes.subheadingGrid}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}

const SubHeaderTitle = ({children}) => {
  const classes = useStyles(useTheme())
  return (
    <Typography variant='h2' className={classes.subheadingTitle}>
      {children}
    </Typography>
  )
}

const SubHeaderParagraph = ({children}) => {
  const classes = useStyles(useTheme())
  return (
    <Typography className={classes.subheadingParagraph}>
      {children}
    </Typography>
  )
}

export default function HomePageContent() {
  const classes = useStyles(useTheme())

  return (
    <>
      <ContentWrapper>
        <SubHeaderGrid>
          <SubHeaderTitle>
            How to Create a Webhook
          </SubHeaderTitle>
        </SubHeaderGrid>
      </ContentWrapper>

      <ContentWrapper>
        <Paper className={classes.paper} elevation={1} variant="outlined" square>
          <List>
            <ListItem>
              <ListItemText>
                <Typography variant="h3" className={classes.instructionsHeader}>
                  <strong>Step 1</strong>: <Link href="/webflow-webhook-api-token">Generate a Webflow API Token</Link>
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography variant="h3" className={classes.instructionsHeader}>
                  <strong>Step 2</strong>: <Link href="/webhook-destination-url">Add a "test" destination url</Link>
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography variant="h3" className={classes.instructionsHeader}>
                  <strong>Step 3</strong>: <Link href="/webflow-webhook-trigger">Create your Webhook</Link>
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                  <Typography variant="h3" className={classes.instructionsHeader}>
                    <strong>Done</strong>! <Link href="/testing-webflow-webhooks">Test your Webhook</Link>
                  </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Paper>
      </ContentWrapper>

      <ContentWrapper>
        <SubHeaderGrid>
          <SubHeaderTitle>
            Learn more about Webflow Webhooks
          </SubHeaderTitle>
        </SubHeaderGrid>
      </ContentWrapper>

      <ContentWrapper>
        <EmailCourse />
      </ContentWrapper>

      <ContentWrapper>
        <Typography>
          You can also go through my NoCodeQuest tutorials:
        </Typography>
      </ContentWrapper>

      <ContentWrapper>
        <Paper className={classes.paper} elevation={1} variant="outlined" square>
          <Link href="https://nocodequest.com/webflow-webhooks-with-integromat/" target="_blank">
            <LinkImage>
              <img src="/images/webflow-webhooks-with-integromat-what-is-a-webhook.png" alt="How to use Webhooks with Webflow"  className={classes.image} />
            </LinkImage>
            <LinkTitle>
              How to use Webhooks with the Webflow CMS
            </LinkTitle>
          </Link>
        </Paper>
      </ContentWrapper>

      <ContentWrapper>
        <Paper className={classes.paper} elevation={1} variant="outlined" square>
          <Link href="https://nocodequest.com/webflow-api/" target="_blank">
            <LinkImage>
              <img src="/images/webflow-api-painful.png" alt="How to use the Webflow API"  className={classes.image} />
            </LinkImage>
            <LinkTitle>
              How to use the Webflow API
            </LinkTitle>
          </Link>
        </Paper>
      </ContentWrapper>

      <ContentWrapper>
        <Paper className={classes.paper} elevation={1} variant="outlined" square>
          <Link href="https://nocodequest.com/webhooks-versus-apis/" target="_blank">
            <LinkImage>
              <img src="/images/webhooks-versus-apis.png" alt="How Webflow APIs & Webhooks are different"  className={classes.image} />
            </LinkImage>
            <LinkTitle>
              How Webflow Webhooks & APIs are different
            </LinkTitle>
          </Link>
        </Paper>
      </ContentWrapper>

      <ContentWrapper>
        <Paper className={classes.paper} elevation={1} variant="outlined" square>
          <Link href="https://nocodequest.com/when-to-use-webhooks-versus-apis/" target="_blank">
            <LinkImage>
              <img src="/images/when-to-use-webhooks-versus-apis.png" alt="I'm confused, should I be using a Webhook or an API?"  className={classes.image} />
            </LinkImage>
            <LinkTitle>
              "I'm confused, should I be using a Webhook or an API?"
            </LinkTitle>
          </Link>
        </Paper>
      </ContentWrapper>

      <ContentWrapper>
        <SubHeaderGrid>
          <SubHeaderTitle>
            Put Your New Learnings into Practice
          </SubHeaderTitle>
          <SubHeaderParagraph>
          </SubHeaderParagraph>
        </SubHeaderGrid>
      </ContentWrapper>

      <ContentWrapper>
        <Paper className={classes.paper} elevation={1} variant="outlined" square>
          <Link href="https://nocodequest.com/simple-webflow-form-submits-with-integromat/" target="_blank">
            <LinkImage>
              <img src="/images/simple-webfow-form-submits-with-integromat-using-webhooks.png" alt="Simple Webflow form submissions with Integromat using Webflow Webhooks"  className={classes.image} />
            </LinkImage>
            <LinkTitle>
              Simple Webflow form submissions with Make / Integromat using Webflow Webhooks
            </LinkTitle>
          </Link>
        </Paper>
      </ContentWrapper>

      <ContentWrapper>
        <Paper className={classes.paper} elevation={1} variant="outlined" square>
          <Link href="https://nocodequest.com/webflow-form-submit-to-google-sheet/" target="_blank">
            <LinkImage>
              <img src="/images/webflow-form-submit-to-google-sheet.png" alt="How to Submit Native Webflow Forms into a Google Sheet"  className={classes.image} />
            </LinkImage>
            <LinkTitle>
              "I am just trying to find an easy solution to submit Native Webflow Forms into a Google Sheet."
            </LinkTitle>
          </Link>
        </Paper>
      </ContentWrapper>

      <ContentWrapper>
        <SubHeaderGrid>
          <SubHeaderTitle>
            Checkout all of the Tutorials & Screencasts @
            <Link href="https://nocodequest.com" target="_blank">
              &nbsp;NoCodeQuest.com
            </Link>
          </SubHeaderTitle>
        </SubHeaderGrid>
      </ContentWrapper>

      <br />
      <br />
      <br />
      <br />
    </>
  )
}
