import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './pages/Home'
import SetupWebflowWebhookApiToken from './pages/SetupWebflowWebhookApiToken'
import SetupWebhookDestinationUrl from './pages/SetupWebhookDestinationUrl'
import SetupWebflowWebhookTrigger from './pages/SetupWebflowWebhookTrigger'
import SetupTestingWebflowWebhooks from './pages/SetupTestingWebflowWebhooks'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/webflow-webhook-api-token" element={<SetupWebflowWebhookApiToken />} />
        <Route path="/webhook-destination-url" element={<SetupWebhookDestinationUrl />} />
        <Route path="/webflow-webhook-trigger" element={<SetupWebflowWebhookTrigger />} />
        <Route path="/testing-webflow-webhooks" element={<SetupTestingWebflowWebhooks />} />
      </Routes>
    </BrowserRouter>
  )
}
