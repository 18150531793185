import React from 'react'
import EmailCourseSignup from './EmailCourseSignup'

import {useTheme, makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(4),
    backgroundColor: '#f4f4f0',

    [theme.breakpoints.up('desktop')]: {
      width: '70%',
    },
  },

  paperEmailCourse: {
    width: '100%',
    padding: theme.spacing(4),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    backgroundColor: 'white',

    [theme.breakpoints.up('desktop')]: {
      width: '70%',
    },
  },

  contentWrapper: {
    paddingBottom: theme.spacing(5),
  },

  subheadingGrid: {
    paddingTop: theme.spacing(15),
    width: '100%',

    [theme.breakpoints.up('desktop')]: {
      width: '70%',
    },
  },

  subheadingTitle: {
    fontSize: '1.3rem',
    fontWeight: 'normal',
    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '1.8rem',
    },
  },

  subheadingParagraph: {
    fontSize: '1rem',
    fontWeight: 'normal',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '1.1rem',
    },
  },

  instructionsHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0),

    [theme.breakpoints.up('desktop')]: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
    },
  },

  section: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('desktop')]: {
      marginTop: theme.spacing(5),
    },
  },

  link: {
    textDecoration: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(4),
      fontSize: '1.3rem',
    },
  },

  emailCourse: {
    textDecoration: 'none',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    backgroundColor: '#f4f4f0',
    border: '1px solid #c0c0c0',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '1.3rem',
      textAlign: 'center',
      marginTop: theme.spacing(6),
    },
  },

  emailCourseTitle: {
    textDecoration: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    textAlign: 'center',
    color: '#555555',

    [theme.breakpoints.up('desktop')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(4),
      fontSize: '1.6rem',
    },
  },

  emailCourseSubTitle: {
    textDecoration: 'none',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'left',
    color: 'black',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '1.3rem',
      padding: theme.spacing(4),
      marginBottom: theme.spacing(0),
    },
  },

  emailCourseList: {
    textDecoration: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    color: 'black',

    [theme.breakpoints.up('desktop')]: {
      marginBottom: theme.spacing(1),
    },
  },

  emailCourseNotice: {
    color: '#555555',
    fontSize: '0.8rem',
    textAlign: 'center',
    topMargin: theme.spacing(-100),
  },

  image: {
    width: '100%',

    [theme.breakpoints.up('desktop')]: {
      width: '50%',
    },
  },

  badge: {
    height: '40px',
    minWidth: '40px',
    fontSize: '1.5rem',
  },

  appBar: {
    position: 'relative',
  },

  dialogClose: {
    textAlign: 'right',
  },

  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  dialogContent: {
    backgroundColor: '#f4f4f0',
  },

  dialogOpenButton: {
    marginTop: theme.spacing(6),
  },

  dialogInfo: {
    paddingBottom: theme.spacing(5),
  },
}))

export default function EmailCourse({children}) {
  const classes = useStyles(useTheme())

  const EmailCourseTitle = ({children}) => {
    const classes = useStyles(useTheme())
    return (
      <Typography className={classes.emailCourseTitle}>
        {children}
      </Typography>
    )
  }

  const EmailCourseSubTitle = ({children}) => {
    const classes = useStyles(useTheme())
    return (
      <div className={classes.emailCourseSubTitle}>
        {children}
      </div>
    )
  }

  return (
    <Paper className={classes.paperEmailCourse} elevation={5}>
      <EmailCourseTitle>
        🤩 Email Course
      </EmailCourseTitle>

      <EmailCourseSubTitle>
        My free 5-day email course gives you the missing deep dive showing you how to:
      </EmailCourseSubTitle>
      <div>
        <List>
          <ListItem>
            <ListItemIcon>
              ✅
            </ListItemIcon>
            <ListItemText className={classes.emailCourseList}>
              Always be successful when using my simple approach to Webhooks.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              ✅
            </ListItemIcon>
            <ListItemText className={classes.emailCourseList}>
              Discover "hidden" Webflow Webhooks - <i>and use them as your secret advantage</i>.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              ✅
            </ListItemIcon>
            <ListItemText className={classes.emailCourseList}>
              Setup Webflow Webhooks ( the easy way ), and save your expensive Zapier, Make & Integromat tasks.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              ✅
            </ListItemIcon>
            <ListItemText className={classes.emailCourseList}>
              Save time, and pain, by learning these concepts only once - <i>but reuse what you've learned forever</i>.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              ✅
            </ListItemIcon>
            <ListItemText className={classes.emailCourseList}>
              Step-by-step walk through using Webhooks to "<strong>Gate Access to Webflow with Stripe Payments</strong>" 🤯
            </ListItemText>
          </ListItem>
        </List>

        <div className={classes.emailCourse}>
          <EmailCourseSignup/>
        </div>
      </div>

      <Typography className={classes.emailCourseNotice}>
        * Includes the NoCodeQuest weekly newsletter, unsubscribe anytime.
      </Typography>
    </Paper>
  )
}
