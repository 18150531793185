import React from 'react'

import {useTheme, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AdjustIcon from '@material-ui/icons/Adjust'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Container from '@material-ui/core/Container'

import Header from './globals/Header'
import HeaderWrapper from './globals/HeaderWrapper'
import SectionWrapper from './globals/SectionWrapper'
import EmailCourse from './globals/EmailCourse'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',

    [theme.breakpoints.up('desktop')]: {
      maxWidth: '80%',
    },
  },

  paper: {
    width: '100%',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(10),
    backgroundColor: '#efefe9',

    [theme.breakpoints.up('desktop')]: {
    },
  },

  title: {
    fontSize: '2.8rem',
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(15),
    textAlign: 'center',
  },

  description: {
    textAlign: 'left',
    fontWeight: 'normal',
    color: '#000000',
    fontSize: '1.0rem',
    marginBottom: theme.spacing(5),
  },

  image: {
    width: '100%',
  },

}))

export default function SetupWebflowWebhookApiToken(){
  const classes = useStyles(useTheme())

  return (
    <Container className={classes.root}>
      <HeaderWrapper>
        <Header/>
      </HeaderWrapper>

      <SectionWrapper>
        <Paper className={classes.paper} elevation={2}>
          <Grid container justifyContent="center">
            <img src="/images/webhook-creator-instructions-webflow-token.png" alt="Add your Webflow Token to the Webhook Creator" className={classes.image} />
          </Grid>
        </Paper>

        <Typography variant="h1" className={classes.title}>
          Generate a Webflow API Token
        </Typography>

        <Typography className={classes.description}>
          This is step 1 of <Link href="/">creating Webflow Webhooks</Link>. Follow the step-by-step instructions below and then continue
          with step 2.
        </Typography>

        <Box className={classes.root}>
          <List>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Log into your Webflow Dashboard and find the project you'd like to use.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Open up that projects settings.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Tap on the "Integrations" tab.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Scroll to the bottom.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Find the "API Access" section.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Tap the "Generate API Token" button. If you already have an API Token generated, used that instead.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Tap the "Copy" button to copy the Token to your clipboard.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Paste the newly copied API Token in the WebhookCreator field "Webflow API Token".
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowForwardIcon />
              </ListItemIcon>
              <ListItemText>
                Move on to step 2: <Link href="/webhook-destination-url">Add a "test" destination url</Link>
              </ListItemText>
            </ListItem>
          </List>

        </Box>
      </SectionWrapper>

      <Box display="flex" justifyContent="center" alignItems="center">
        <EmailCourse/>
      </Box>
    </Container>
  )
}
