import React from 'react'

import {useTheme, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AdjustIcon from '@material-ui/icons/Adjust'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Container from '@material-ui/core/Container'

import Header from './globals/Header'
import HeaderWrapper from './globals/HeaderWrapper'
import SectionWrapper from './globals/SectionWrapper'
import EmailCourse from './globals/EmailCourse'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',

    [theme.breakpoints.up('desktop')]: {
      maxWidth: '80%',
    },
  },

  paper: {
    width: '100%',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(10),
    backgroundColor: '#efefe9',

    [theme.breakpoints.up('desktop')]: {
    },
  },

  title: {
    fontSize: '2.8rem',
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(15),
    textAlign: 'center',
  },

  description: {
    textAlign: 'left',
    fontWeight: 'normal',
    color: '#000000',
    fontSize: '1.0rem',
    marginBottom: theme.spacing(5),
  },

  image: {
    width: '100%',
  },

}))

export default function SetupWebflowWebhookTrigger(){
  const classes = useStyles(useTheme())

  return (
    <Container className={classes.root}>
      <HeaderWrapper>
        <Header/>
      </HeaderWrapper>

      <SectionWrapper>

        <Paper className={classes.paper} elevation={2}>
          <Grid container justifyContent="center">
            <img src="/images/webhook-creator-instructions-trigger-type.png" alt="Select a Webflow Webhook Trigger" className={classes.image} />
          </Grid>
        </Paper>

        <Typography variant="h1" className={classes.title}>
          Create your Webhook
        </Typography>

        <Typography className={classes.description}>
          This is step 3 of <Link href="/">creating Webhooks</Link>. Follow the step-by-step instructions below then continue
          with testing your newly setup Webhook!
        </Typography>

        <Box className={classes.root}>
        <List>
          <ListItem>
           <ListItemIcon>
             <AdjustIcon />
           </ListItemIcon>
           <ListItemText>
             For this walk through, choose the "Collection Item Created" trigger.
           </ListItemText>
          </ListItem>
          <ListItem>
           <ListItemIcon>
             <AdjustIcon />
           </ListItemIcon>
           <ListItemText>
             Tap the "Create Webflow Webhook" button.
           </ListItemText>
          </ListItem>
          <ListItem>
           <ListItemIcon>
             <AdjustIcon />
           </ListItemIcon>
           <ListItemText>
             Done 😎
           </ListItemText>
          </ListItem>
          <ListItem>
           <ListItemIcon>
             <AdjustIcon />
           </ListItemIcon>
           <ListItemText>
             Reload the browser window opened to the Webflow Project Settings --> Integrations tab.
           </ListItemText>
          </ListItem>
          <ListItem>
           <ListItemIcon>
             <AdjustIcon />
           </ListItemIcon>
           <ListItemText>
             Once the browser window has reloaded, you should see your new Webhook showing up.
           </ListItemText>
          </ListItem>
          <ListItem>
           <ListItemIcon>
             <ArrowForwardIcon />
           </ListItemIcon>
           <ListItemText>
             Done! <Link href="/testing-webflow-webhooks">Test your new Webhook</Link>
           </ListItemText>
          </ListItem>
          </List>
        </Box>
      </SectionWrapper>

      <Box display="flex" justifyContent="center" alignItems="center">
        <EmailCourse/>
      </Box>

    </Container>
  )
}
