import React from 'react'

import {useTheme, makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AdjustIcon from '@material-ui/icons/Adjust'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Container from '@material-ui/core/Container'

import Header from './globals/Header'
import HeaderWrapper from './globals/HeaderWrapper'
import SectionWrapper from './globals/SectionWrapper'
import EmailCourse from './globals/EmailCourse'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',

    [theme.breakpoints.up('desktop')]: {
      maxWidth: '80%',
    },
  },

  paper: {
    width: '100%',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(10),
    backgroundColor: '#efefe9',

    [theme.breakpoints.up('desktop')]: {
    },
  },

  title: {
    fontSize: '2.8rem',
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(15),
    textAlign: 'center',
  },

  description: {
    textAlign: 'left',
    fontWeight: 'normal',
    color: '#000000',
    fontSize: '1.0rem',
    marginBottom: theme.spacing(5),
  },

  image: {
    width: '100%',
  },

}))

export default function SetupTestingWebflowWebhooks(){
  const classes = useStyles(useTheme())

  return (
    <Container className={classes.root}>
      <HeaderWrapper>
        <Header/>
      </HeaderWrapper>

      <SectionWrapper>

        <Paper className={classes.paper} elevation={2}>
          <Grid container justifyContent="center">
            <img src="/images/webhook-creator-instructions-request-bin-results.png" alt="Test your Webflow Webhook!" className={classes.image} />
          </Grid>
        </Paper>

        <Typography variant="h1" className={classes.title}>
          Test your Webhook
        </Typography>

        <Typography className={classes.description}>
          This is the testing step from <Link href="/">creating Webflow using Webhooks</Link>. Follow the step-by-step instructions below.
        </Typography>

        <Box className={classes.root}>
          <List>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Open your Webflow Projects CMS Editor.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Create a new CMS Collection.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                For this quick walk through, select the "Blog Posts" Collection Template and tap the "Create Collection" button.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Populate this new CMS Collection with sample items. Tap the "Add 5 Posts" button.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Switch back to your RequestBin page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Find 5 new "POST" requests.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Choose one and look at the value of the "name" field.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText>
                Go back to the CMS Editor and find that very same item by name.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowForwardIcon />
              </ListItemIcon>
              <ListItemText>
                Done! Now go back to the homepage and read how to <Link href="/">make it more awesome</Link>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </SectionWrapper>

      <Box display="flex" justifyContent="center" alignItems="center">
        <EmailCourse/>
      </Box>

    </Container>
  )
}
