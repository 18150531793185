import React from 'react'

import {useTheme, makeStyles} from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(0),
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.up('desktop')]: {
      width: '70%',
    },
  },

  contentWrapper: {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    marginTop: theme.spacing(6),
  },
}))

const ContentWrapper = ({children}) => {
  const classes = useStyles(useTheme())
  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={classes.contentWrapper}>
      <Paper className={classes.paper} elevation={0} square>
        <Typography component="p">
          {children}
        </Typography>
      </Paper>
    </Box>
  )
}

export default function ToolIntro() {
  return (
    <>
      <ContentWrapper>
        Setting up Webhooks on Webflow is painful.
      </ContentWrapper>

      <ContentWrapper>
        You need to understand how to use "curl", know what an "Authorization Bearer" is,
        and can only take advantage of this using your terminals "command line" 🥴
      </ContentWrapper>

      <ContentWrapper>
        🤩 That ends now.
      </ContentWrapper>

      <ContentWrapper>
        Webhook Creator gives you simple point & click access to all of Webflows advanced Webhooks.
      </ContentWrapper>

      <ContentWrapper>
        Quickly create real-world solutions for both you & your clients ontop of Webflow, right now.
      </ContentWrapper>
    </>
  )
}
