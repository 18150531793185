import { createTheme } from '@material-ui/core/styles'
import '@fontsource/poppins';
// import './filsonPro.css'

//const colors = {
  // primary: {
  //   hotOrange: '#FF6600',
  //   warmBeige: '#F7DECB'
  // },
  // secondary: {
  //   green: '#305738',
  //   eggYellow: '#FFD940'
  // },
  // accents: {
  //   beige: '#D1983E',
  //   black: '#000000',
  //   blue: '#1F5D90',
  //   grassGreen: '#AAC371',
  //   gray: '#66676B',
  //   green: '#51AD64',
  //   white: '#FFFFFF',
  //   red: '#dc3545' // Grabbed from current code
  // }
//}

const theme = createTheme({
  palette: {
    background: {
      default: '#efefe9'
    }
  },

  // palette: {
  //   common: {
  //     black: colors.accents.black,
  //     white: colors.accents.white
  //   },
  //   primary: {
  //     main: colors.primary.hotOrange,
  //     light: colors.primary.warmBeige,
  //     contrastText: '#fff'
  //   },
  //   secondary: {
  //     main: colors.secondary.green
  //   },
  //   success: {
  //     main: colors.accents.green
  //   },
  //   grey: {
  //     500: colors.accents.gray
  //   },
  //   error: {
  //     main: colors.accents.red
  //   }
  // },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,

      phone: 0,
      mediumPhone: 300,
      largePhone: 400,
      tablet: 600,
      desktop: 960,
      largeDesktop: 1280,
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h2: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.5,
    },
    h3: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: 2.40
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.5,
    },
    body1: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 1.5,
    },
    caption: {
      fontWeight: 350,
      fontSize: 14,
      lineHeight: 1.43,
    },
  },
})

// theme.typography = {
  // ...theme.typography,
  // h2: {
  //   ...theme.typography.h2,
  //   [theme.breakpoints.up('tablet')]: {
  //     fontSize: 32,
  //     lineHeight: 1
  //   },
  //   [theme.breakpoints.up('desktop')]: {
  //     fontSize: 40,
  //   },
  // },
  // subtitle1: {
  //   ...theme.typography.subtitle1,
  //   [theme.breakpoints.up('tablet')]: {
  //     fontSize: 18,
  //     lineHeight: 1.33
  //   },
  //   [theme.breakpoints.up('desktop')]: {
  //     fontSize: 24,
  //     lineHeight: 1.2
  //   }
  // },
  // body1: {
  //   ...theme.typography.body1,
  //   [theme.breakpoints.up('tablet')]: {
  //     fontSize: 16
  //   }
  // }
// }

export default theme
