import React from 'react'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import { mobilePaddingLeftRight, mobilePaddingTopBottom, desktopPaddingLeftRight, desktopPaddingTopBottom } from '../../constants'

// Component Styles
const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    paddingTop: theme.spacing(mobilePaddingTopBottom),
    paddingLeft: theme.spacing(mobilePaddingLeftRight),
    paddingRight: theme.spacing(mobilePaddingLeftRight),
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.up('desktop')]: {
      paddingTop: theme.spacing(desktopPaddingTopBottom),
      paddingLeft: theme.spacing(desktopPaddingLeftRight),
      paddingRight: theme.spacing(desktopPaddingLeftRight),
      paddingBottom: theme.spacing(3),
    }
  },
}))

export default function SectionWrapper({children}) {
  const classes = useStyles(useTheme())

  return (
    <div className={classes.sectionWrapper}>
        {children}
    </div>
  )
}
