import React from 'react'
import Container from '@material-ui/core/Container'
import Tool from '../components/Tool'
import ToolIntro from './ToolIntro'
import Header from './globals/Header'
import HeaderWrapper from './globals/HeaderWrapper'
import SectionWrapper from './globals/SectionWrapper'
import HomePageContent from './HomePageContent'

export default function Home(){
  return (
    <Container>
      <HeaderWrapper>
        <Header/>
      </HeaderWrapper>

      <SectionWrapper>
        <Tool/>
        <ToolIntro/>
        <HomePageContent/>
      </SectionWrapper>
    </Container>
  )
}
